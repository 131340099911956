export const locales = [
    /* eslint-disable global-require */
    {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
    },
    {
        locale: 'es',
        img: require('@/assets/images/flags/es.png'),
        name: 'Español',
    },
    {
        locale: 'sv',
        img: require('@/assets/images/flags/sv.png'),
        name: 'Svenska',
    },
    /* eslint-disable global-require */
]