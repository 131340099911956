<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
      <b-navbar-nav>
        <locale />
      </b-navbar-nav>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
        <b-link @click="logout">
          <span>{{$t('logout')}}</span>
          <feather-icon size="16" icon="LogOutIcon" class="ml-50" />
        </b-link>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BRow,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import StorageKeys from '../../utils/storage-keys'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow,

    // Navbar Components
    DarkToggler,
    Locale
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logout() {
      // Remove access token and user id from localStorage
      localStorage.removeItem(StorageKeys.accessToken)
      localStorage.removeItem(StorageKeys.userId)
      this.$store.dispatch('auth/logout')

      // Redirect to login page
      this.$router.push('/login')
    },
  },
}
</script>
