export default [
  {
    title: 'headers.dashboard',
    route: 'home',
    icon: 'ActivityIcon',
  },
  {
    title: 'headers.properties',
    route: 'property-list',
    icon: 'HomeIcon',
  },
  // {
  //   title: 'Property Actions',
  //   route: 'property-actions',
  //   icon: 'FileIcon',
  // },
  {
    title: 'headers.propertyTypes',
    route: 'property-types',
    icon: 'LayersIcon',
  },
  // {
  //   title: 'Offices',
  //   route: 'offices-list',
  //   icon: 'ArchiveIcon',
  // },
  {
    title: 'headers.demands',
    route: 'demands-list',
    icon: 'BookOpenIcon',
  },
  {
    title: 'headers.zones',
    route: 'zones-list',
    icon: 'MapIcon',
  },
  {
    title: 'headers.users',
    route: 'users-list',
    icon: 'UserIcon',
  },
]
