<template>
  <b-nav-item-dropdown
    right
    variant="link"
    id="dropdown-grouped"
    class="dropdown-language"
  >
    <template #button-content>
      <b-img
        width="20px"
        height="20px"
        :src="currentLocale.img"
        :alt="currentLocale.locale"
      />

      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>

    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLocale(localeObj.locale)"
    >
      <b-img
        width="20px"
        height="20px"
        :src="localeObj.img"
        :alt="localeObj.locale"
      />

      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { locales } from '@/libs/i18n/utils'
import StorageKeys from '../../../../../utils/storage-keys'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },

  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale)
    },
  },

  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
      localStorage.setItem(StorageKeys.locale, locale)
    },
  },

  setup() {
    return {
      locales,
    }
  },
}
</script>
